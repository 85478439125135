import './App.scss';
import Nav from './Nav';
import Timer from './Timer';
import defaultConfig from './config';
import Settings from './Settings';
import {useState} from 'react';


function audio(file) {
  return new Audio(`${file}.mp3`);
}

function App() {
  const [pomodoroCount, setPomodoroCount] = useState(1);
  const [currentPomodoro, setCurrentPomodoro] = useState("pomodoro");
  const [showSettings, setShowSettings] = useState(false);

  function config() {
    const localConfig = localStorage.getItem("config");
    return JSON.parse(localConfig) || defaultConfig
  }

  function showNotification() {
    if (Notification.permission === 'granted') {
      new Notification('Pomodoro Timer', {
        body: 'Time is up!',
        icon: '/favicon.ico'
      });
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          new Notification('Pomodoro Timer', {
            body: 'Time is up!',
            icon: '/favicon.ico'
          });
        }
      });
    }
  }

  function pomodoroComplete() {
    if (config().sound !== "") {
      audio(config().sound).play();
    }

    if (config().notifications) {
      showNotification();
    }

    if (pomodoroCount === (config().pomodoro_set_lenth * 2)-1) {
      setCurrentPomodoro("long_break");
      setPomodoroCount(0);
    } else if (currentPomodoro === "pomodoro") {
      setCurrentPomodoro("short_break");
      setPomodoroCount(pomodoroCount +1);
    } else {
      setCurrentPomodoro("pomodoro");
      setPomodoroCount(pomodoroCount +1);
    }
  }

  function nextPomodoroLength() {
    return(config()[currentPomodoro]);
  }

  function toTitleCase(str) {
    str = str.replace("_", " ");
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }

  function currentPomodoroForDisplay() {
    if(currentPomodoro !== "long_break") {
      return `#${Math.ceil(pomodoroCount/2)}`
    }
  }

  function toggleSettings() {
    setShowSettings(!showSettings);
  }

  return (
    <>
      <Nav showSettings={toggleSettings} />
      <div className="app">
        <div className="pomodoro-type">
          {toTitleCase(currentPomodoro)} 
        </div>
        <Timer key={pomodoroCount} timer={nextPomodoroLength()} pomodoroComplete={pomodoroComplete} />
        <div className="current-pomodoro">
          {currentPomodoroForDisplay()}
        </div>
      </div>
      { <Settings open={showSettings} close={toggleSettings}/> }
    </>
  );
}

export default App;
