import { useEffect, useState } from 'react';
import './Settings.scss';
import defaultConfig from './config';

function Settings(props) {

  const [config, setConfig] = useState(
    JSON.parse(localStorage.getItem('config')) || defaultConfig
  );

  const [saving, setSaving] = useState(false);

  useEffect(() => {}, [props.open])

  useEffect(() => {
    if (saving) {
      localStorage.setItem('config', JSON.stringify(config));
    }
  }, [config, saving])

  function save(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formProps = Object.fromEntries(formData);

    formProps['pomodoro'] = parseInt(formProps['pomodoro']) * 60
    formProps['short_break'] = parseInt(formProps['short_break']) * 60
    formProps['long_break'] = parseInt(formProps['long_break']) * 60
    formProps['notifications'] = formProps['notifications'] === "on"

    for (let key in config) {
      if (!formProps.hasOwnProperty(key)) {
      formProps[key] = config[key];
      }
    }

    setConfig(formProps);
    setSaving(true);
    props.close();
  };

  function testNotification() {
    if (Notification.permission === 'granted') {
      new Notification('Pomodoro Timer', {
        body: 'This is a test notification!',
        icon: '/favicon.ico'
      });
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission === 'granted') {
          new Notification('Pomodoro Timer', {
            body: 'This is a test notification!',
            icon: '/favicon.ico'
          });
        }
      });
    }
  }

  return(
    <div className={`modal-container ${props.open ? '' : 'hidden'}`}>
      <div className="modal">
      <h2>Settings</h2>
        <form onSubmit={save}>
          <p>
            <label>Pomodoro length</label><br />
            <input type="number" defaultValue={config.pomodoro/60} min="1" inputMode="numeric" pattern="[0-9]*" name="pomodoro" />
          </p>
          <p>
            <label>Short break length</label><br />
            <input type="number" defaultValue={config.short_break/60} min="1" inputMode="numeric" pattern="[0-9]*" name="short_break" />
          </p>
          <p>
            <label>Long break length</label><br />
            <input type="number" defaultValue={config.long_break/60} min="1" inputMode="numeric" pattern="[0-9]*" name="long_break" />
          </p>
          <p>
            <label>Alert sound</label><br />
            <select name="sound" defaultValue={config.sound}>
              <option value="egg-timer">Short ring</option>
              <option value="kitchen-timer">Long ring</option>
              <option value="">None</option>
            </select>
          </p>
          <p>
            <label>Enable notifications</label><br />
            <input type="checkbox" name="notifications" defaultChecked={config.notifications} />
            <button type="button" onClick={testNotification}>Test Notification</button>
          </p>
          <button>Save</button>
        </form>
      </div>
    </div>
  );
}

export default Settings;
