import {useState, useEffect} from 'react';
import ClockFace from './ClockFace';

function Timer(props) {
  const [timerRunning, setTimerRunning] = useState(false);
  const [timer, setTimer] = useState(props.timer);
  const pomodoroComplete = props.pomodoroComplete;
  const [timerStarted, setTimerStarted] = useState(Date.now());

  useEffect(() => {
    let counter;
    if (timerRunning) {
      if (timer <= 0) {
        setTimerRunning(false);
        pomodoroComplete();
      }
      counter = setInterval( () => {
        setTimer(props.timer - Math.floor((Date.now() - timerStarted) / 1000))
      }, 1000);
    }
    return () => {
      clearInterval(counter);
    };
  }, [timerRunning, timer, props.timer, pomodoroComplete, timerStarted]);

  useEffect(() => {
    if (timerRunning) {
      document.title = `${Math.ceil(timer / 60)}m`;
    } else {
      document.title = 'Pomodoro';
    }
    return () => {
      document.title = 'Pomodoro';
    };
  }, [timerRunning, timer]);

  function toggleTimer() {
    setTimerStarted(Date.now());
    setTimerRunning(!timerRunning);
  }

  function skip() {
    setTimer(0)
  }

  return(
    <>
      <div className="timer">
        <ClockFace timer={timer} />
      </div>
      <div className="controls">
        <button className="hidden"> > </button>
        <button className="toggle" onClick={toggleTimer}>{timerRunning ? `Stop` : `Start`}</button>
        <button className={`skip ${timerRunning ? "" : "hidden"}`} onClick={skip}> > </button>
      </div>
    </>
  );
}

export default Timer;
