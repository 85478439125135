const config = {
  pomodoro: 25*60,
  short_break: 5*60,
  long_break: 15*60,
  pomodoro_set_lenth: 4,
  sound: "egg-timer",
  notifications: false
};

export default config;
